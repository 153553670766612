.firstSector-web {
    
    width: 100%;
    min-height: 100%;
    background-color: rgb(210, 238, 244);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 0;
    
    transition: all 0.5s;
    
}

.fsBackground {

    background-image: url(/public/back-imp.jpg);
    
}

.startBackground {

    background-image: url(/public/start.jpg);
    
}

.supportBackground {

    background-image: url(/public/back-contact.jpg);
    
}

.firstSectorTitleFont {

    font-size: 7.0em;
    margin-top: 350px;
    margin-left: 100px;
    color: white;
    
}

.impressum {

    font-size: 1.5em;
    max-width: 1000px;
    padding: 15px;
    margin-top: 65px;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;

}

.impressum h3 {

    color: var(--lbl-color-black);
    
}

.impressum strong {

    color: var(--lbl-color-black);
    font-weight: bold;

}

.impressum a, li {

    color: var(--lbl-color-black-opa-08);
    text-decoration: none;
    
}

.impressum p {
    
    color: var(--lbl-color-black-opa-08);
    
}

p.signature {
    
    font-family: "Alex Brush";
    font-size: 1.5em;
    text-align: right;
    color: rgba(52, 188, 216, 1.0);
    
}

.customButtonMore {
    
    width: 169px;
    height: 50px;
    border: 1.5px solid var(--lbl-color-cyan);
    border-radius: 5px;
    text-align: center;
    margin-top: 50px;
    margin-left: calc(50% - 85px);
    z-index: 2;
    cursor: pointer;
    
    transition: 1.5s linear;
    
}

.customButtonMore .innerText {
    
    display: block;
    color: var(--lbl-color-white);
    text-align: center;
    padding: 12px;
    text-decoration: none;
    height: 100%;
    font-size: 1.2em;
    z-index: 10;
    
    transition: 0.5s linear;
    
}

.customButtonMore .innerText:hover:not(.active) {
    
    background-color: rgba(255, 255, 255, 0.2);
    color: var(--lbl-color-cyan);
    
}

h1 {

    font-size: 3.5em;
    margin-left: 20%;

}

.lbls {
    
    margin-left: 25%;
    font-size: 1.0em;
    color: gray;
    
}

input {

    margin-left: 25%;
    width: 50%;
    height: 40px;
    font-size: 1.5em;
    font-family: "Economica";
    color: rgba(52, 188, 216, 1.0);

}

textarea {
    
    margin-left: 25%;
    width: 50%;
    font-size: 1.5em;
    font-family: "Economica";
    color: rgba(52, 188, 216, 1.0);
    resize: none;
    
}

select {
    
    margin-left: 25%;
    width: 50%;
    height: 40px;
    line-height: 40px;
    font-size: 1.5em;
    font-family: "Economica";
    color: rgba(52, 188, 216, 1.0);
    background: rgba(255, 255, 255, 0.99);
    
}

.customButtonSend {
    
    width: 50%;
    height: 50px;
    border: 1.0px solid gray;
    border-radius: 5px;
    text-align: center;
    margin-left: 25%;
    margin-top: 30px;
    color: lightgray;
    text-align: center;
    padding: 12px;
    font-size: 1.2em;
    cursor: pointer;
    
    transition: 0.5s linear;
    
    
}

.customButtonSend:hover:not(.active) {
    
    border: 1.0px solid rgba(52, 188, 216, 1.0);
    background-color: rgba(52, 188, 216, 0.7);
    color: white;
    
}

input, .lbls, textarea, select, h1 {
    
    transition: 0.5s linear;
    
}