#stayDetailView #innerWrapper {

  width: 700px;

}

@media screen and (min-width:1200px) {


}

@media screen and (max-width:1199px) and (min-width:950px) {


}

@media screen and (max-width:949px) and (min-width:760px) {


}

@media screen and (max-width:759px) {

    #stayDetailView #innerWrapper {

        height: calc( 100% - 110px);
      
      }

}