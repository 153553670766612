.value-title {

    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--lbl-color-lightblack);

}

.bottom-title {

    width: 100%;
    font-size: 1.0rem;
    color: var(--lbl-color-lightblack);
    text-align: center;

}

@media screen and (max-width:1199px) and (min-width:950px) {


}

@media screen and (max-width:949px) and (min-width:760px) {




}

@media screen and (max-width:759px) {

    .value-title {

        font-size: 0.9rem;
    
    }

    .bottom-title {

        font-size: 0.8rem;
    
    }

}