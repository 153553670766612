.main-div {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

}

.main-div .left-column {

    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    width: 30%;
    padding: 0 10px 0 20px;
    overflow: visible;
    order: 0;

}

.main-div .middle-column {

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    gap: 10px;
    width: 40%;
    padding: 0 10px 10px 10px;
    overflow: visible;
    order: 1;

}

.main-div .right-column {

    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    width: 30%;
    padding: 0 20px 0 10px;
    overflow: visible;
    order: 2;

}

.statistics-small {

    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 45%;
    overflow: visible;

}

.statistics-small #month-lbl::after {

    display: flex;
    position: relative;
    content: 'unfold_more';
    font-family: 'Material Symbols Outlined';
    color: var(--lbl-color-cyan);
    padding: 0 5px;

    transition: all 0.2s ease-in-out;

}

.statistics-small #month-lbl:hover::after {
    color: var(--lbl-color-cyan-hover);
    transform: scale(1.2);
}

.progress-bar {

    display: block;
    position: relative;
    width: 100%;
    height: 10px;
    background-color: lightgray;
    border-radius: 5px;
    margin-top: 5px;

}

.progress-bar .value-bar {

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--lbl-color-cyan);
    border-radius: 5px;

    transition: width 0.2s ease-in;

}

.expand-chartview {

    position: absolute;
    left: 1%;
    top: 80px;
    width: 98% !important;
    height: calc(100% - 90px) !important;
    z-index: 2;

}

@media screen and (max-width:1199px) and (min-width:950px) {

    .statistics-small {

        width: 60%;
    
    }

}

@media screen and (max-width:949px) and (min-width:760px) {




}

@media screen and (max-width:759px) {

    .statistics-small {

        width: 98%;
        margin-left: 1%;
    
    }

    .main-div {

        flex-direction: column;
        height: auto;

    }

    .main-div .middle-column {
    
        width: 100%;
        padding: 5px;
        order: 0;
    
    }

    .main-div .left-column {

        width: 100%;
        padding: 5px;
        order: 1;
    
    }
    
    
    
    .main-div .right-column {
    
        width: 100%;
        padding: 5px;
    
    }

}