/*@import url('https://fonts.googleapis.com/icon?family=Material+Icons');*/
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://fonts.googleapis.com/css?family=Alex+Brush');
@import url('https://fonts.googleapis.com/css?family=Economica');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,400');
/*@import 'navigationSlider.css';*/
/*@import '../javascript/jquery-ui/jquery-ui.min.css';*/
/*@import '/eazyact/javascript/jquery-ui/jquery-ui.min.css';*/

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;

}

:root, :root.light {

  --body-bg-color: #ffffff;
  --body-lbl-color: #236a72;
  --wrapper-bg-color: rgb(255, 255, 255);

  --cell-bg-color-hover: rgb(240, 240, 240);
  --cell-bg-color: rgb(247, 247, 247);

  --input-bg-color: rgb(245, 245, 245);
  --wrapper-cell-bg-color: rgba(0, 0, 0, 0.144);

  --bg-color-lightwhite: rgb(184, 184, 184);
  --bg-color-white: white;
  --bg-color-lightblack: rgb(250, 250, 250);

  --bg-color-searchinput: rgb(255, 255, 255);

  --lbl-color-black: black;
  --lbl-color-lightblack: rgb(112, 111, 111);
  --lbl-color-verylightblack: rgb(148, 148, 148);
  --lbl-color-black-opa-08: rgba(0, 0, 0, 0.8);

  --lbl-color-lightcyan: rgb(150, 233, 250);
  --lbl-color-cyan: rgb(0, 213, 255);
  --lbl-color-cyan-hover: rgb(0, 176, 211);
  --lbl-color-cyan-light: rgb(167, 238, 252);

  --lbl-color-white: rgb(235, 235, 235);
  --lbl-color-lightwhite: rgb(221, 221, 221);
  --lbl-color-verylightwhite: rgb(137, 137, 137);

  --lbl-color-register-text: darkblue;

  --line-color-strong: #c1c1c14b;
  --line-color: #b6b5b591;
  --line-color-verylight: #c8665941;
  --line-color-light: #ca847a;

  --border-radius: 1px;

  --boxshadow-color: black;
  --tile-bg-hover: rgb(250, 250, 250);
  --tile-bg-selected: rgb(247, 247, 247);
  --bg-color-popup: rgb(250, 250, 250);
  --bg-color-inputview: rgb(250, 250, 250);
  --alert-btn-hover: rgb(240, 240, 240);
  --bg-color-navbar: rgba(244, 253, 255);
  --navbar-shadow-color: rgba(0,0,0,0.2);
  --line-color-popupbutton: rgb(194, 244, 255);
  --date-weekend-bg: rgb(230, 230, 230);

  --lbl-color-lightblack-for-date: rgb(112, 111, 111);

  --popup-hover-color: rgba(0, 0, 0, 0.2);

  /*--------------------------new-input-style-variables---------------------------*/

  --lbl-new-input-color-black: black;
  --lbl-new-input-color-lightblack: rgb(72, 72, 72);

  --line-color-inputBox-light: rgba(0, 0, 0, 0.6);
  --line-color-inputBox-dark: rgba(0, 0, 0, 1.0);

}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --body-bg-color: black;
    --bg-color-white: rgb(16, 16, 26);
    --lbl-color-black: var(--lbl-color-white);
    --lbl-color-lightblack: var(--lbl-color-lightwhite);
    --lbl-color-verylightblack: var(--lbl-color-verylightwhite);
    --lbl-color-black-opa-08: rgba(255, 255, 255, 0.8);
    --boxshadow-color: white;
    --tile-bg-hover: rgb(20, 20, 20);
    --tile-bg-selected: rgb(30, 30, 30);
    --bg-color-popup: rgb(25, 25, 25);
    --bg-color-inputview: rgb(50, 50, 70);
    --lbl-new-input-color-black: white;
    --lbl-new-input-color-lightblack: rgb(189, 189, 189);
    --alert-btn-hover: rgb(45, 45, 45);
    --bg-color-navbar: rgba(20, 10, 25);
    --navbar-shadow-color: rgba(255,255,255,0.2);
    --line-color-popupbutton: rgb(50, 10, 0);
    --date-weekend-bg: rgb(70, 70, 90);
    --line-color: #ededed91;
    --cell-bg-color: rgb(30, 30, 30);
    --line-color-inputBox-light: rgba(255, 255, 255, 0.6);
    --line-color-inputBox-dark: rgb(255, 255, 255);
    --lbl-color-register-text: lightblue;
    --lbl-color-lightblack-for-date: rgb(81, 81, 81);
    --popup-hover-color: rgba(220, 220, 220, 0.3);

  }
} 

:root.dark {
  --body-bg-color: black;
  --bg-color-white: rgb(16, 16, 26);
  --lbl-color-black: var(--lbl-color-white);
  --lbl-color-lightblack: var(--lbl-color-lightwhite);
  --lbl-color-verylightblack: var(--lbl-color-verylightwhite);
  --lbl-color-black-opa-08: rgba(255, 255, 255, 0.8);
  --boxshadow-color: white;
  --tile-bg-hover: rgb(20, 20, 20);
  --tile-bg-selected: rgb(30, 30, 30);
  --bg-color-popup: rgb(25, 25, 25);
  --bg-color-inputview: rgb(50, 50, 70);
  --lbl-new-input-color-black: white;
  --lbl-new-input-color-lightblack: rgb(189, 189, 189);
  --alert-btn-hover: rgb(45, 45, 45);
  --bg-color-navbar: rgba(20, 10, 25);
  --navbar-shadow-color: rgba(255,255,255,0.2);
  --line-color-popupbutton: rgb(50, 10, 0);
  --date-weekend-bg: rgb(70, 70, 90);
  --line-color: #ededed91;
  --cell-bg-color: rgb(30, 30, 30);
  --line-color-inputBox-light: rgba(255, 255, 255, 0.6);
  --line-color-inputBox-dark: rgb(255, 255, 255);
  --lbl-color-register-text: lightblue;
  --lbl-color-lightblack-for-date: rgb(81, 81, 81);
  --popup-hover-color: rgba(220, 220, 220, 0.3);

}

body {

  font-family: 'Economica', "Century Gothic", CenturyGothic, sans-serif;
  color: var(--lbl-color-black);
  margin: 0;
  padding: 0;
  background-color: var(--body-bg-color);

}

body, html { height: 100% };

.blur {

  -webkit-filter: blur(4px);
     -moz-filter: blur(4px);
      -ms-filter: blur(4px);
       -o-filter: blur(4px);
          filter: blur(4px);

}

.flex-container, .flex {

  display: flex !important;
  overflow-y: hidden;

}

.default-hover-bg-effect {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.default-hover-bg-effect:hover {
  background-color: var(--date-weekend-bg);
  transition: all 0.2s ease-in-out;
}

.wrap {

  flex-wrap: wrap;

}

.center {
  justify-content: center;
  align-items: center;
}

.center-y {
  align-items: center;
}

.column {
  flex-direction: column;
  justify-content: flex-start;
}

.w100prc {
  width: 100% !important;
  max-width: none !important;
}

.h100prc {
  height: 100% !important;
  max-height: none !important;
}

.disable-pointer-events {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.truncate-text {

  display: inline-block !important;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.default-icon {

  width: 30px;
  padding: 0 5px;
  color: var(--lbl-color-cyan);
  font-size: 1.1rem;
  font-family: 'Material Symbols Outlined' !important;

}

.clickable-icon {

  width: 30px;
  padding: 0 5px;
  color: var(--lbl-color-cyan);
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Material Symbols Outlined';

  transition: 0.2s ease-in;

}

.clickable-icon.selected {

  font-variation-settings:
  'FILL' 1,
  'wght' 600,
  'GRAD' 0,
  'opsz' 24;

  color: var(--lbl-color-cyan-hover);

}

.clickable-icon:hover {

  color: var(--lbl-color-cyan-hover);

}

.material-icons {
  transition: 0.2s ease-in;
}

.material-icons.hover:hover {

  color: var(--lbl-color-cyan-hover);

}

.property-img {

  display: block;
  width: 100px;
  height: 100px;
  border: 1px solid black;
  border: 1px solid rgba(0, 0, 0, 0.4);
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  color: darkgray;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  border-radius: 5px;
  float: left;
  cursor: pointer;

  -moz-transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;

}

.property-img:hover {
  border-color: gray;
}

.portrait {

  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.6);
  background-image: url(/public/ef_std_img.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  -moz-transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;

}

.blur {

  -webkit-filter: blur(8px);
   -moz-filter: blur(8px);
    -ms-filter: blur(8px);
     -o-filter: blur(8px);
        filter: blur(8px);

}

.portrait:hover {
  transform: scale(1.1);
}

.container-background-standard {

  position: relative;
  width: 100%;
  height: auto;
  background-color: var(--bg-color-lightblack);
  border-radius: var(--border-radius);
  border: 1px solid var(--line-color);

}

.img-view-style {

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  text-align: center;
  color: darkgray;
  color: var(--lbl-color-black);
  overflow: hidden;
  font-size: 1.5em;

}

.list-card {
  position: relative;
  flex-direction: column;
}

.list-card:hover {
  box-shadow: 0 0 5px;
  margin-left: 5px !important;
}

.list-card .title {

  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;

}

.body-lbl {

  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 1.7em;
  color: var(--body-lbl-color);

}

.main-btn {

  display: block;
  width: 50%;
  height: 30px;
  margin-left: 25%;
  border-radius: 10px;
  border: 1px solid rgba(52, 188, 216, 0.6);
  float: left;
  color: rgba(52, 188, 216, 1.0);;
  cursor: pointer;
  text-align: center;
  overflow: hidden;

  -moz-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
          transition: 0.2s linear;

}

#header {

  display: block;
  width: 100%;
  height: 200px;
  background-color: rgb(21,215,237);
  float: left;
  border-bottom: 1px solid white;

}

.main-btn.green {
  border-color: green;
}

.main-btn-animation {

  width: 0%;
  height: 100%;
  background-color: rgba(52, 188, 216, 0.4);

  -moz-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
          transition: 0.2s linear;

}

.not-allowed {
  cursor: not-allowed !important;
}

.main-btn-animation.green {

  background-color: lightgreen;

}

.main-btn-lbl {

  position: relative;
  top: -100%; 
  left: 0; 
  width: 100%; 
  height: 100%;
  padding: 4px;

}

.main-btn:hover {

  border: 1px solid rgba(52, 188, 216, 1.0);
  color: black;

}

.main-btn:hover > .main-btn-animation {

  width: 100%;

}


.alert-content {

position: fixed;
bottom: -400px;
width: 100%;
height: 70px;
background-color: black;
background-color: rgba(0, 0, 0, 0.7);
color: white;
font-size: 1.2em;
padding: 10px;
text-align: center;
z-index: 20;

    -moz-transition: 0.7s linear;
 -webkit-transition: 0.7s linear;
         transition: 0.7s linear;

}

.alert-content a {

color: lightgray;
text-decoration: none;
cursor: pointer;

}

.firstSector {
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 0;
    padding-top: 100px;
    overflow: hidden;
    overflow-y: scroll;
    
    transition: 0.2s linear;
    
}

.priceSector-web {
    
  width: 100%;
  background-color: rgb(0, 132, 141);
  float: left;
  
}

.infoSector-web {
    
  width: 100%;
  background-color: var(--bg-color-white);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  float: left;

  transition: all 0.5s ease-in-out;
  
}

.trialSector-web {
    
  width: 100%;
  height: auto;
  text-align: center;
  padding: 20px 10px 20px 10px;
  background-color:  rgb(0, 210, 247);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  float: left;
  
}

.infoBox {

  display: block;
  width: 25%;
  height: 300px;
  float: left;

  transition: all 0.5s;

}

.infoBox:hover {

  background-color: var(--popup-hover-color);

}

.infoBox-icon {

  display: block;
  width: 100%;
  height: 100px;
  line-height: 100px;
  border: none;
  margin: 0;
  padding: 0;
  text-align: center;
  color: var(--lbl-color-cyan);
  font-size: 80px;
  float: left;

}

.infoBox-headline {

  width: 100%;
  height: 30px;
  color: var(--lbl-color-black);
  text-align: center;
  font-size: 1.5em;
  float: left;

}

.infoBox-text {

  width: 100%;
  color: var(--lbl-color-lightblack);
  padding: 20px;
  text-align: center;
  font-size: 1.2em;
  float: left;
  
}

.firstSector.no-nav {

  width: 100%;

}

.navigationOpened {

  width: calc(100% - 200px);

}

#content-table {

  position: relative;
  display: block;
  height: 100%;
  width: calc(100% - 350px);
  overflow: auto;
  float: left;

}

#dashboard-table {

  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  float: left;
  overflow-y: auto;

  transition: all 0.3s;

}

div.secondSector {

  width: 100%;
  background-color: var(--body-bg-color);
  float: left;
  z-index: 1;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 100px;

  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

}

div.footer {

  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  background-color: none;
  background-color: rgb(50, 50, 50);
  color: var(--lbl-color-white);
  margin-top: 20px;

}

div.footerInfoSector {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

div.footerInfoSector a {

    color: white;
    text-decoration: none;
    font-weight: bold;

}

.slideButton {

position: relative;
height: 40px;
width: 45px;
top: -20px;
left: 5%;
background-color: white;
border-radius: 15%;
cursor: pointer;

-moz-transition: 0.5s linear;
-webkit-transition: 0.5s linear;
transition: 0.5s linear;

}

i {
    margin-top: 13px;
    margin-left: 15px;
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 5px;
}

.slideButton:hover {
    
    top: -30px;
    
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.close {
    
    position: absolute;
    top: -20px;
    right: 0;
    font-size: 4.0em;
    padding: 15px;
    cursor: pointer;
    z-index: 10;
    
    -moz-transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
    transition: 0.2s linear;
    
}

.close:hover, .lrBtn:hover {
    
    color: red;
    
}

.cell-style-title {

  /*position: absolute;*/
  width: calc(100% - 50px);
  height: 50%;
  margin-left: 50px;
  padding-top: 14px;
  color: var(--lbl-color-lightwhite);
  text-align: center;
  font-size: 1.0em;

}

.cell-style-title.top {

  padding-left: 5px;
  padding-top: 2px;
  text-align: left;
  font-size: 1.2em;

}

.cell-style-title.bottom {

  padding-left: 5px;
  padding-top: 0px;
  text-align: left;
  font-size: 1.1em;

}

.wrapper-view-title {

  display: block;
  height: 30px;
  color: var(--lbl-color-black);
  border-bottom: 1px solid var(--line-color);
  padding: 2.5px;
  margin-left: 2%;
  margin-bottom: 20px;
  font-size: 1.3em;
  float: left;

}

#oo-btn-widget {

    position: relative;
    display: inline-block;
    width: 15%;
    height: 30px;
    border: 1px solid black;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: rgb(170, 41, 41);
    text-align: center;
    border-radius: 5px;
    padding: 3.5px;
    font-family: 'Economica', "Century Gothic", CenturyGothic, sans-serif;
    cursor: pointer;

    -moz-transition: 0.5s linear;
 -webkit-transition: 0.5s linear;
         transition: 0.5s linear;

}

#oo-btn-widget:hover {
  color: lightgreen;
}

.mainBtn-style {

  position: relative;
  display: block;
  width: 50%;
  height: 30px;
  line-height: 25px;
  bottom: 0;
  margin-bottom: 10px;
  text-align: center;
  padding: 5px;
  float: left;
  cursor: pointer;
  color: black;
  font-size: 1.2em;

  -moz-transition: 0.2s linear;
 -webkit-transition: 0.2s linear;
         transition: 0.2s linear;

}

.btn-cancel {

  color: rgb(255, 89, 89);

}

.btn-save {

  color: rgb(6, 255, 6);
}

.mainBtn-style:hover {

  color: white;

}

.mainBtn-style.selected {

  background-color: rgba(255,255,255,0.2);

}

.btn-light-hover:hover {

  color: black;

}

#search-bar-widget {

  display: block;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid var(--line-color);
  overflow: hidden;
  float: left;

}

.search-input-style {

  display: block;
  width: 100%;
  height: 40px;
  font-size: 1.1em;
  color: var(--lbl-color-lightblack);
  background-color: var(--bg-color-searchinput);
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Economica', "Century Gothic", CenturyGothic, sans-serif;
  border: none;
  outline: none;
  float: left;

}

.search-button-style {

  position: relative;
  display: block;
  width: 10%;
  height: 40px;
  font-size: 24px;
  color: rgba(52, 188, 216, 1.0);
  padding: 8px;
  text-align: center;
  border: none;
  outline: none;
  float: left;
  cursor: pointer;
  margin: 0;
  border: none;

  -moz-transition: 0.2s linear;
 -webkit-transition: 0.2s linear;
         transition: 0.2s linear;

}

.cell {

  display: block;
  float: left;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid var(--line-color-strong)

}

.search-button-style:hover {

  background-color: rgba(52, 188, 216, 0.8);
  color: white;

}

.clearfix {
  clear: both;
}

.no_top_margin {
  margin-top: 0;
}

.no_left_margin {
  margin-left: 0;
}

.no_bottom_margin {
  margin-bottom: 0;
}

.no_right_margin {
  margin-right: 0;
}

.color-verylightwhite {
  color: var(--lbl-color-verylightwhite) !important;
}

.color-white {
  color: var(--lbl-color-white) !important;
}

.color-lightwhite {
  color: var(--lbl-color-lightwhite) !important;
}

.color-verylightblack {
  color: var(--lbl-color-verylightblack) !important;
}

.color-black {
  color: var(--lbl-color-black) !important;
}

.color-lightblack {
  color: var(--lbl-color-lightblack) !important;
}

.color-cyan {
  color: var(--lbl-color-cyan) !important;
}

.color-darkcyan {
  color: var(--lbl-color-cyan-hover) !important;
}

.color-darkred {
  color: darkred !important;
}

.border-bottom-strong {
  border-bottom: 1px solid var(--line-color);
}

.border-top-strong {
  border-top: 1px solid var(--line-color-strong);
}

.border-right-strong {
  border-right: 1px solid var(--line-color-strong);
}

.border-left-strong {
  border-left: 1px solid var(--line-color-strong);
}

.default-hover-effect {
  transition: all 0.2s ease-in-out;
}

.default-hover-effect:hover {
  color: var(--lbl-color-cyan-hover);
  border-bottom: 1px solid var(--lbl-color-cyan-hover);
}

#add_immo {

  display: block;
  width: 100%;
  height: auto;
  margin-top: 300px;
  float: left;
  color: rgb(182, 182, 182);
  font-size: 3.0em;
  text-align: center;
}

.add_immo_icon {

  display: block;
  width: 100%;
  height: 40px;
  margin: 0;
  cursor: pointer;
  border: none;
  font-size: 3.0em;
  text-align: center;
  padding-top: 8px;
  color: rgb(182, 182, 182);
  float: left;

  -moz-transition: 0.2s linear;
-webkit-transition: 0.2s linear;
       transition: 0.2s linear;

}

.add_immo_icon:hover {
  color: rgb(146, 146, 146);
}

.text-align-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

input::placeholder, textarea::placeholder {

  color: rgb(145, 145, 145);

}

div.contactSector {

  text-align: center;
  padding: 20px 10px 20px 10px;
  background-color: rgb(0, 210, 247);

}

div.customButtonContact {

  position: relative;
  top: 0;
  width: 200px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border: 1.5px solid white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;

  transition: 0.0s ease;

}

div.customButtonContact .innerText {

  display: block;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  height: 100%;
  font-size: 1.2em;
  
  transition: 0.5s linear;

}

div.customButtonContact .innerText:hover:not(.active) {

  background-color: rgba(255, 255, 255, 0.4);
  color: white;

}

.boxTitleFont {

  width: 750px;
  font-family: "Alex Brush";
  text-align: center;
  color: white;
  font-size: 3.0em;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;

}

.boxTitleFontSmall { 

  font-size: 1.3em;
  color: rgba(255, 255, 255, 0.9);

}

.mainText {
    
  display: block;
  width: 50%;
  margin-left: 25%;
  margin-top: 15%;
  z-index: 2;
  
  transition: 1.5s linear;
  
}

.headlines {

  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: var(--lbl-color-cyan);
  font-size: 4.0em;


}

.headlinesText {

  display: block;
  width: 100%;
  color: var(--lbl-color-cyan);
  font-size: 1.9em;
  margin-top: 40px;
  padding: 20px;
  text-align: center;

}

.efColor {

  color: rgb(52, 188, 216);
  
}

.opaOverlay {

  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 200;
  
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  
  }
  
  .flashOpaOverlay {
  
  opacity: 0.0;
  
  }
  
  .removeOpaOverlay {
  
  display: none;
  
  }

  .default-cursor {
    cursor: default !important;
  }

/*ANCHOR PopUpViewStyle*/
/*---------------------------PopUpViewStyle------------------------------*/

.popup-view {

  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
  left: 0;
  width: max-content;
  height: 300px;
  font-size: 1.0em;
  background-color: var(--bg-color-popup);
  border-radius: 5px;
  box-shadow: 0px 0px 7px var(--boxshadow-color);
  overflow-y: scroll;
  padding: 10px;
  z-index: 200;

  transition: 0.2s linear;

}

.popup-view .lbl-container {

  display: flex;
  align-items: center;
  width: 100%;
  color: var(--lbl-color-black);
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.0);
  cursor: pointer;

  transition: 0.2s linear;

}

.popup-view .lbl-container .sub-lbl {

  color: var(--lbl-color-lightblack);
  padding: 10px 5px 5px 5px;
  font-size: 0.8em;

}

.popup-view .lbl-container:hover {

  background-color: var(--popup-hover-color);
  border-radius: 5px;

}

.clickable-popUpTitle {

  position: relative;
  width: 100%;
  font-size: 1.0em;
  padding: 5px;
  color: var(--lbl-color-black);
  cursor: pointer;
  float: left;

}

.clickable-popUpButton {

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 5px 0px 5px 10px;
  text-align: center;
  color: var(--lbl-color-black);
  border: 1px solid var(--line-color-popupbutton);
  border-radius: 10px;
  box-shadow: 0px 0px 2px;
  cursor: pointer;

  -moz-transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
          transition: 0.1s linear;

}

.clickable-popUpButton::after {

  content: 'unfold_more';
  font-family: 'Material Symbols Outlined';
  padding: 0 5px 0 5px;
  color: var(--lbl-color-cyan);
  float: right;

}

.clickable-popUpButton:hover {

  background-color: var(--tile-bg-selected);

}

/*ANCHOR IconTextBtnStyle*/
/*---------------------------IconTextBtnStyle------------------------------*/

.icon-text-btn {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  color: var(--lbl-color-cyan);
  cursor: pointer;

  transition: all 0.2s ease-in;

}

.icon-text-btn:hover {

  background-color: var(--cell-bg-color-hover);

}

.icon-text-btn .icon {

  width: 40px;
  padding: 0 5px;
  font-family: 'Material Symbols Outlined';

}

/*ANCHOR RoundedBtnStyle*/
/*---------------------------RoundedBtnStyle------------------------------*/

.rounded-btn {

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 200px;
  height: 35px;
  color: var(--lbl-color-black);
  background-color: rgba(47,220,255, 0.8);
  border-radius: 25px;
  cursor: pointer;
  font-family: 'Economica', "Century Gothic", CenturyGothic, sans-serif;
  font-size: 1.0;

  transition: 0.2s linear;

}

.rounded-btn.disabled {

  opacity: 0.3;
  cursor:not-allowed;

}

.rounded-btn.cancelBtn, .deleteBtn {

  width: 50px;
  font-weight: bold;
  background-color: transparent;
  margin-left: 20px;

}

.rounded-btn.deleteBtn {

  color: red;

}

.rounded-btn.mailAddBtn {

  width: max-content;
  height: 25px;
  margin: 10px 0 0 5px;
  padding: 0 5px 0 5px;
  background-color: orange;
  float: left;

}

.rounded-btn.mailAddBtn:hover {

  background-color: rgb(239, 155, 0);
  color: var(--lbl-color-white);

}

.rounded-btn.mailAddBtn .material-icons {

  width: 100%;
  text-align: center;
  color: var(--lbl-color-lightblack);
  line-height: 18px;

}

#mailToAddresses, #mailCcAddresses {

  display: block;
  height: 25px;
  margin-top: 10px;
  float: left;

}

#mailToAddresses .rounded-btn, #mailCcAddresses .rounded-btn {

  margin-left: 5px;
  height: 100%;
  width: max-content;
  text-align: center;
  line-height: 25px;
  padding: 0 10px 0 10px;
  float: left;

}

.rounded-btn.cancelBtn:hover {

  background-color: rgba(0, 0, 0, 0.157);

}

.rounded-btn.deleteBtn:hover {

  background-color: rgba(255, 0, 0, 0.804);
  color: var(--lbl-color-white);

}

.rounded-btn div {

  text-align: center;

}

.rounded-btn:hover {

  background-color: rgba(47,220,255, 1.0);

}

/*ANCHOR NewOverlayView*/
/*---------------------------NewOverlayView------------------------------*/

.backView {

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 30;
  background-color: black;
  background-color: rgba(134, 134, 134, 0.3);
  opacity: 1.0;

  /*animation-name: blend-in;
  animation-duration: 100ms;
  animation-iteration-count: 1;*/

}

@keyframes blend-in {
  from {opacity: 0;}
  to {opacity: 1.0;}
}

#overlayView {

  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.321);
  z-index: 1000;

}

.backView #innerWrapper {

  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: 100px;
  width: 400px;
  height: auto;
  max-height: 800px;
  padding: 10px;
  background-color: var(--bg-color-white);
  font-size: 1.0em;
  color: var(--lbl-color-black);
  border-radius: 10px;
  box-shadow: 0px 0px 3px var(--boxshadow-color);
  overflow: hidden;
  overflow-y: scroll;

  /*animation-name: slide-in;
  animation-duration: 100ms;
  animation-iteration-count: 1;*/

}

@keyframes slide-in {
  from {top: 100%;}
  to {top: 100px;}
}


.backView #innerWrapper .title {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: var(--lbl-color-black);
  border-bottom: 1px solid var(--line-color);
  margin-bottom: 20px;
  font-size: 1.3em;

}

.backView #innerWrapper .lbl {

  display: block;
  position: relative;
  width: 96%;
  color: var(--lbl-color-black);
  margin-left: 2%;

}

.backView #innerWrapper #popOverSheet {

  display: block;
  position: absolute;
  padding: 10px;
  margin: auto;
  width: 99%;
  height: 100%;
  top: 100%;
  left: 0.5%;
  background-color: var(--bg-color-white);
  font-size: 1.0em;
  color: var(--lbl-color-black);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px 0px 5px black;
  overflow-y: hidden;

  transition: 0.1s linear;

}

.backView #innerWrapper #popOverSheet.open {
  top: 10px;
}

.backView #innerWrapper .material-icons {

  color: var(--lbl-color-cyan);
  cursor: pointer;
  float: left;

  -moz-transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
          transition: 0.1s linear;

}

.backView #innerWrapper .material-icons:hover {

  color: var(--lbl-color-cyan-hover);

}

.backView #innerWrapper .action-alert-btn {

  width: 100%;
  height: 35px;
  line-height: 35px;
  bottom: 0;
  color: var(--lbl-color-black);
  text-align: center;
  border-top: 1px solid var(--line-color);
  cursor: pointer;

  transition: 0.2s linear;

}

.backView #innerWrapper .action-alert-btn:hover {

  background-color: var(--alert-btn-hover);

}

#overlayView #innerWrapper .btn {

  padding: 10px;
  color: var(--lbl-color-cyan);
  float: right;
  cursor: pointer;

  -moz-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
          transition: 0.2s linear;

}

#overlayView #innerWrapper .btn:hover {

  color: var(--lbl-color-cyan-hover);

}

#overlayView #innerWrapper .btn.deleteBtn {

  padding-left: 0;
  color: red;
  float: left;

}

#overlayView #innerWrapper .btn.deleteBtn:hover {

  color: rgb(230, 1, 1);

}

/*ANCHOR .cn-container*/

.cn-container {

  display: inline-block;
  width: 65%;
  margin-left: 17.5%;
  cursor: pointer;
  border-bottom: 1px solid var(--line-color);

  transition: all 0.2s ease;

}

.cn-container:hover {

  background-color: rgb(236, 235, 235);

}

.cn-container .material-icons {

  display: block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 2px;
  font-size: 0.9em;
  cursor: default !important;

}

.cn-container .type-lbl {

  display: block;
  padding-top: 1px;
  padding-left: 5px;
  color: var(--lbl-color-lightblack);
  font-size: 0.8rem;
  float: left;

}

.cn-container .value-lbl {

  display: block;
  padding-right: 5px;
  color: var(--lbl-color-black);
  font-size: 0.9rem;
  float: right;

}

/*ANCHOR ContactNote-Style*/

#contactContainer,
#noteContainer,
#personsContainer {

  display: flex;
  justify-content: center;
  gap: 10px;
  width: calc(98% - 30px);
  margin-left: 2%;
  padding: 5px;
  text-align: center;
  overflow-x: scroll;

}

.cn-data-view {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px;
  background-color: var(--alert-btn-hover);
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 3px var(--line-color-inputBox-light);

  transition: all 0.2s ease-in;

}

.cn-data-view:hover {

  box-shadow: 0 0 8px var(--line-color-inputBox-dark);

}

.cn-data-view .material-icons {

  font-size: 0.9rem;
  cursor: default;
  padding: 2px 2px 2px 5px;
  pointer-events: none;
  float: right;

}

.cn-data-view .titleLabel {

  color: var(--lbl-color-lightblack);
  padding: 5px 10px 5px 10px;

}

.cn-data-view .subLabel {

  color: var(--lbl-color-black);
  padding: 0 10px 5px 10px;

}

.no-data-div {

  display: inline-block;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  color: var(--lbl-color-lightblack);
  padding: 10px;

}

/*ANCHOR ShadowView */
.shadowBox {

  display: flex;
  border: 1px solid var(--line-color);
  border-radius: 7px;
  padding: 20px;
  width: auto;
  height: auto;
  box-shadow: 0 0 2px var(--boxshadow-color);
  margin: 3px 2px;
  background-color: var(--bg-color-white);

  transition: all 0.2s ease-in-out;

}

.shadowBox.tile {
  position: relative;
}

.shadowBox.tile:hover {

  box-shadow: 0 0 10px;
  background-color: var(--tile-bg-hover);
  cursor: pointer;
  margin-top: 10px;

}

.shadowBox.tile.selected {

  box-shadow: 0 0 10px;
  background-color: var(--tile-bg-selected);
  margin-top: 10px;

}

.default-backview {

  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  height: 98% ;
  margin-top: 100%;

  transition: all 0.2s ease-in-out;

}

.default-backview.opened {
  margin-top: 1%;
}

/*ANCHOR HelpBox*/
/*-----------------------------HelpBox-----------------------------------*/

.helpBox {

  display: block;
  position: absolute;
  color: var(--lbl-color-lightblack);
  border: 1px solid var(--line-color);
  border-radius: 7px;
  padding: 20px;
  width: auto;
  height: auto;
  overflow-y: hidden;
  box-shadow: 0 0 3px;

  transition: all 0.2s ease-in-out;

}

/*ANCHOR InfoWidget*/
/*-----------------------------InfoWidget-----------------------------------*/

#infoWidget {

  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 100px;
  right: -500px;
  width: 250px;
  height: auto;
  padding: 10px;
  background-color: var(--bg-color-white);
  font-size: 1.0em;
  color: var(--lbl-color-black);
  border-radius: 10px;
  box-shadow: 0px 0px 8px red;
  border: 1px solid darkred;
  overflow-y: hidden;
  z-index: 2000;

  animation-name: 'slide-in-from-right';
  animation-duration: 5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;

}

@keyframes slide-in-from-right {
  0% {right: -500px;}
  5% {right: 20px;}
  90% {right: 20px;}
  100% {right: -500px;}
}

/*ANCHOR NewTableCellStyle*/
/*---------------------------NetTableCellStyle------------------------------*/

.table-cell {

  display: block;
  width: 100%;
  height: auto;
  padding: 5px;
  color: var(--lbl-color-black);
  font-size: 1.0em;
  border-bottom: 1px solid var(--line-color);
  cursor: pointer;
  background-color: var(--cell-bg-color);

  transition: 0.2s linear;

}

.table-cell:hover {

  background-color: var(--cell-bg-color-hover);

}

.table-cell .titleLabel {

  width: 100%;
  font-size: 1.1em;
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}

.table-cell .subLabel {

  width: 100%;
  color: var(--lbl-color-lightblack);
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.table-cell .material-icons {

  color: var(--lbl-color-cyan);
  font-size: 18px;
  float: left;

}

.table-cell .icon-view {

  width: calc(100% / 3);
  padding: 5px 0 5px 0;
  float: left;

}

.table-cell .icon-view .lbl {

  opacity: 0.5;
  padding: 0 5px 0 5px;

}

/*ANCHOR .slideview-btn-menu*/

.slideview-btn-menu {
    
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  font-size: 0.8rem;
  background-color: var(--bg-color-searchinput);
  cursor: pointer;
  color: var(--lbl-color-black);
  border-top: 1px solid var(--line-color);
  border-bottom: 1px solid var(--line-color);
  border-left: 1px solid var(--line-color);

  transition: 0.2s linear;
  
}

.slideview-btn-menu:hover {

  font-size: 1.0rem;

}

.slideview-btn-menu.open {

  right: calc(100% - 30px);

}

.slideview {

  display: flex;
  position: absolute;
  height: 100%;
  width: calc(100% - 30px);
  top: 0;
  left: 100%;
  background-color: rgb(235, 235, 235);

  transition: 0.2s linear;

}

.slideview.open {

  left: 30px;

}

.slideview .btn {
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  cursor: pointer;
  color: var(--lbl-color-lightblack);

  transition: 0.2s ease-out;
  
  }

.slideview .btn:hover {

  background-color: var(--bg-color-lightwhite);

}

/*ANCHOR .salutation-wrapper */

.salutation-wrapper {

  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: 40px;
  margin: 20px 2px;
  border: 1px solid var(--line-color);
  border-radius: 5px;
  background-color: var(--cell-bg-color);
  box-shadow: 0 0 3px var(--boxshadow-color);
  cursor: pointer;

}

.salutation-wrapper .btn {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  color: var(--lbl-color-lightblack);

  transition: all 0.2s ease-in;

}

.salutation-wrapper .btn.selected, 
.salutation-wrapper .btn:hover {

  color: var(--lbl-color-black);
  background-color: var(--date-weekend-bg);
  border-bottom: 2px solid lightgray;

}

/*ANCHOR .date-container */

.se-date-container {

  display: flex;
  position: relative;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  width: 49%;
  height: 40px;
  padding: 2px;
  overflow: visible;
  cursor: pointer;

}

.se-date-container .border {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid var(--lbl-color-cyan);
  border-radius: 5px;
  padding-top: 3px;

}

.se-date-container .border.from::before {

  display: block;
  position: absolute;
  left: 5px;
  content: 'chevron_right';
  color: green;
  font-family: 'Material Symbols Outlined';
  font-size: 1.4rem;


}

.se-date-container .border.from.to::before {

  content: 'chevron_left';
  color: red;


}

.se-date-container .day {

  position: absolute;
  top: -10px;
  color: var(--lbl-color-cyan) !important;
  font-size: 1.1rem;
  padding: 0 2px;
  background-color: white !important;

}

/*ANCHOR .rate-widget-class */

.rate-widget-class {

  display: flex;
  justify-content: center;
  align-items: center;

}

.rate-widget-class .star {

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Material Symbols Outlined';
  font-size: 1.0em;
  color: rgb(205, 205, 0);
  cursor: pointer;

  transition: all 0.2s ease-in;

}

.rate-widget-class .star.blacklist {
  color: red;
}

.rate-widget-class .star:hover {
  transform: scale(1.4);
}

/*-----------------------------------------Loader---------------------------------------*/

/*.loader {

  position: absolute;
  display: block;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  border: 16px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border-top: 16px solid #11a0ff;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 40;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}*/

.group {

  position: absolute;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  left: calc(50% - 40px);
  top: 40%;
  z-index: 40;

}
  
.bigSqr {

  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  overflow: hidden;
  transform-origin: bottom left;
  animation: bigSqrShrink 1s linear infinite;

}

.square {

  position: absolute;
  width: 20px;
  height: 20px;
  background: var(--lbl-color-cyan-hover);
  border: 1px solid white;
 
}

.first {

  left: 0px;
  top: 20px;
  
} 
  
.second {

  left: 20px;
  top: 20px;
  animation: drop2 1s linear infinite;
  
}
  
.third {

  left: 0px;
  top: 0px;
  animation: drop3 1s linear infinite;
 
}
   
.fourth {

  left: 20px;
  top: 0px;
  animation: drop4 1s linear infinite;
  
}
  
.text {

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: rgba(52, 188, 216, 1.0);
  padding: 8px;
  width: 100%;
  
}
  
@keyframes bigSqrShrink {

  0%
  {transform: scale(1)}
  90%
  {transform: scale(1)}
  100%
  {transform: scale(0.5)}

  }
  
@keyframes drop2 {

  0%
  {transform: translateY(-50px)}
  25%
  {transform: translate(0)}
  100%
  {transform: translate(0)}

}
    
@keyframes drop3 {

  0%
  {transform: translateY(-50px)}
  50%
  {transform: translate(0)}
  100%
  {transform: translate(0)}

}
 
@keyframes drop4 {

  0%
  {transform: translateY(-50px)}
  75%
  {transform: translate(0)}
  100%
  {transform: translate(0)}

}

.content-backview {

  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;

}

.list-column {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  height: 100%;
  overflow: visible;
  gap: 10px;

}

.navigation-view-bar {

  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  overflow: visible;

}

.navigation-view-bar .main-title {

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--lbl-color-cyan);
  font-size: 1.5rem;

}

.standard-fontsize {

  font-size: 1.0em;

}

.standard-iframe {

  margin-top: 20px;
  width: 100%;
  height: 700px;

}

@media screen and (min-width:1200px) {


}

@media screen and (max-width:1199px) and (min-width:950px) {


}

@media screen and (max-width:949px) and (min-width:760px) {


}

@media screen and (max-width:759px) {

  .shadowBox {

    padding: 10px;
  
  }

  .firstSector {
    padding-top: 70px;
  }

  .content-backview {

    gap: 0;

  }

  .list-column {

    padding-top: 10px;
    width: 100%;
  
  }

  .backView #innerWrapper {

    width: 98% !important;
    
  }

  #iFrameView #innerWrapper{

    top: 80px !important;
    height: calc( 100% - 90px ) !important;

  }

  .navigation-view-bar {

    margin-top: 10px;
  }

  .navigation-view-bar .main-title {

    font-size: 1.2rem;
  
  }

  .standard-fontsize {

    font-size: 0.8em;
    
  }

  .standard-iframe {

    height: 500px;
  
  }

  #infoBox {

    width: 98% !important;

  }

}

