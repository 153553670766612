.alphabet-char {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

}

.alphabet-char:hover, .alphabet-char.selected {
    background-color: var(--lbl-color-cyan);
}