.weekday-backview {

    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 7);
    height: calc(100% / 6);
    overflow: visible;

}

.weekday-backview .date-default {

    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--lbl-color-black);
    overflow: visible;
    cursor: pointer;

    transition: all 0.1s ease-in-out;

}

.weekday-backview .date-default:hover {
    transform: scale(1.2);
}

.weekday-backview .date-default.weekend {

    color: var(--lbl-color-verylightblack);

}

.weekday-backview .date-default.today {

    color: var(--lbl-color-white);
    background-color: darkred;
    clip-path: circle(40%);

}

.weekday-backview .date-default.selected-start-end {

    color: var(--lbl-color-white);
    background-color: var(--lbl-color-cyan-hover);
    border-radius: 5px;
    width: 90%;
    height: 70%;
    font-size: 0.9em;
    box-shadow: 0px 0px 3px black;

}

.weekday-backview .date-default.selected {

    color: var(--lbl-color-lightblack-for-date);
    background-color: var(--lbl-color-cyan-light);
    height: 60%;
    font-size: 0.9em;

}

.weekday-backview .date-default.holiday::before {

    content: '';
    position: absolute;
    left: 5px;
    top: calc(50% - 1.5px);
    background-color: darkred;
    clip-path: circle(50%);
    height: 3px;
    width: 3px;

}

.weekday-backview .date-default.booked::after {

    content: '';
    position: absolute;
    right: 2px;
    top: calc(50% - 2.5px);
    background-color: var(--lbl-color-cyan);
    clip-path: circle(50%);
    height: 5px;
    width: 5px;

}

.weekday-backview .date-default.blocked::after {

    content: '';
    position: absolute;
    right: 3px;
    top: calc(50% - 1.5px);
    background-color: gray;
    clip-path: circle(50%);
    height: 3px;
    width: 3px;

}