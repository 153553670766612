.bar {

    position: absolute;
    display: block;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: black;
    cursor: pointer;
    border-radius: 0 10px 0 10px;
    padding: 7px 5px;
    z-index: 100;
    text-align: center;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    -moz-transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
            transition: 0.2s linear;

}

.booking-cell.angelegt, .bar.angelegt {

    background-color: rgb(187, 69, 69);

}

.booking-cell.angeboten, .bar.angeboten {

    background-color: rgb(250, 204, 51);

}

.booking-cell.gebucht, .bar.gebucht {

    background-color: rgb(131, 159, 235);

}

.booking-cell.angezahlt, .bar.angezahlt {

    background-color: rgb(168, 204, 126);

}

.booking-cell.bezahlt, .bar.bezahlt {

    background-color: rgb(4, 155, 36);

}

.booking-cell.blockiert, .bar.blockiert {

    background-color: rgb(165, 165, 165);

}

.booking-cell.importiert, .bar.importiert {

    background-color: rgb(5, 143, 124);

}

.booking-cell.clicked, .bar.clicked, .bar:hover {

    border: 2px solid red;
    /*background-color: rgb(211, 25, 25);*/
    box-shadow: 1px 1px 3px rgb(46, 45, 45);

}

.booking-cell:hover {

    background-color: rgb(211, 25, 25);

}

.multi-calendar-dates-card {

    position: relative;
    width: 60px;
    height: 100%;
    font-size: 0.8em;
    border-right: 1px solid var(--line-color);
    overflow: visible;
    float: left;
    cursor: pointer;

}

.multi-calendar-dates-card:hover::after {

    content: 'add_circle';
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--lbl-color-cyan);
    font-family: 'Material Symbols Outlined';
    font-size: 1.5em;

}

.block-title-card {

    padding: 5px 10px;
    border-radius: 3px;
    background-color: var(--lbl-color-cyan);
    cursor: pointer;

    transition: all 0.2s ease-in-out;

}

.block-title-card:hover {

    background-color: var(--lbl-color-cyan-hover);

}

#multiCalendarPropertysTable, #singleListCalendarMonthTable {

    display: block;
    position: relative;
    max-height: calc(100% - 180px);
    width: 10%;
    overflow-y: scroll;
    float: left;
    padding: 20px 0;

}

#multiCalendarPropertysTable {

    width: 13%;

}

#multiCalendarPropertysTable .propertycard {

    display: flex;
    align-items: center;
    width: 100%;

}

#multiCalendarPropertysTable::-webkit-scrollbar, #singleListCalendarMonthTable::-webkit-scrollbar {
    display: none;
}

#singleListCalendarMonthTable .monthcard {

    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    border-bottom-width: 6px;
    padding: 10px;
    height: 91px;
    
}

#multiCalendarContentTable, #singleListCalendarContentTable {

    flex-direction: column;
    max-height: calc(100% - 180px);
    position: relative;
    width: calc( 90% - 8px );
    overflow: scroll;
    float: left;
    padding: 20px 0;

}

#multiCalendarContentTable {

    width: calc( 87% - 8px );

}

#propertyDetailView #innerWrapper {

    width: 700px;
    overflow: visible;
  
}

#multiCalendarStayDetailsView {

    flex-direction: column;
    height: 100%;
    position: absolute;
    right: -10px;
    border-radius: 0;
    box-shadow: -2px 2px 3px black;
    z-index: 201;

}

@media screen and (min-width:1200px) {


}

@media screen and (max-width:1199px) and (min-width:950px) {


}

@media screen and (max-width:949px) and (min-width:760px) {


}

@media screen and (max-width:759px) {

  #singleListCalendarMonthTable {

    max-height: calc(100% - 190px);
    padding: 10px 0;

  }

  #multiCalendarPropertysTable, #multiCalendarContentTable, #singleListCalendarContentTable {

    height: 100% !important;
    max-height: none !important;
    padding: 10px 0;

  }

  #multiCalendarPropertysTable #yearLbl {

    display: none !important;

  }

  #multiCalendarPropertysTable .propertycard #propertyName, #menuBtn {

    display: none !important;

}

  #singleListCalendarMonthTable .monthcard { 

    justify-content: center;
    text-align: center;

  }

  #propertyDetailView #innerWrapper {

    height: calc( 100% - 110px);
    overflow: scroll;
  
  }

  #multiCalendarStayDetailsView {

    display: block;
    height: calc(100% - 90px);
    width: 99% !important;
    left: 0;
    overflow-y: scroll;

  }

}