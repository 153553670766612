.input-template-style {
  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    gap: 7px;
    width: 100%;
    height: 60px;
    font-size: 1.0em;
    color: var(--lbl-color-white);
    border: 1px solid var(--line-color-inputBox-light);
    background-color: var(--bg-color-inputview);
    border-radius: 5px;
  
    transition: 0.1s linear;
  
  }

  .input-template-style #title {

    display: flex;
    align-items: center;
    height: 30px;
    padding: 5px 10px;
    color: var(--lbl-new-input-color-lightblack);
  
  }

  .input-template-style input {

    width: calc(100% - 20px);
    height: 100%;
    font-size: 1.3em;
    padding: 0 10px 0 10px;
    color: var(--lbl-new-input-color-black);
    background-color: transparent;
    border: none;
    outline: none;
    font-family: 'Economica', "Century Gothic", CenturyGothic, sans-serif;
  
  }

  .input-template-style:focus-within {
    
    border: 2px solid var(--line-color-inputBox-dark);
  
  }
  
  .input-template-style.error {
      
    border-color: red;
  
  }

  .input-template-style textarea {

    width: 100%;
    height: 100%;
    font-size: 1.0em;
    padding: 10px;
    color: var(--lbl-new-input-color-black);
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    font-family: 'Economica', "Century Gothic", CenturyGothic, sans-serif;
  
  }

  .input-template-style .clickable {
    cursor: pointer;

    transition: all 0.2s ease-in-out;
  }

  .input-template-style .clickable:hover {
    
   transform: scale(1.05);

  }