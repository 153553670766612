.section {

    display: flex;
    width: 100%;
    gap: 30px;
    align-items: flex-start;
    margin-top: 20px;
    overflow-y: visible;
}

.header-wrapper {

    display: flex;
    align-items: center;
    width: 60%;
    overflow-y: visible;

}

.header-wrapper .property-img {

    width: 100px;
    margin-right: 20px;

}

.header-wrapper .property-data {

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: calc(100% - 100px);
    overflow-y: visible;
    
}

.header-wrapper .property-data .top-wrapper {

    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    gap: 10px;
    overflow-y: visible;

}

.address-wrapper {

    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 40%;
    overflow-y: visible;

}

.bottom-section {

    display: flex;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
}

.bottom-section .margin-10 {

    margin-top: 10px;

}

.services-wrapper {

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;

}

.services-wrapper .service {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 5px;

}

.input-wrapper {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;

}

.input-wrapper.margin-top-10 {

    margin-top: 10px;

}

.date-wrapper {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    overflow: visible;
    margin-top: 15px;

}

.expose-header-section {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    padding-bottom: 10px;

}

.expose-header-section .input-wrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 170px);
    gap: 10px;

}

.expose-header-section .input-wrapper .bottom-wrapper {

    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;

}

@media screen and (min-width:1200px) {


}

@media screen and (max-width:1199px) and (min-width:950px) {


}

@media screen and (max-width:949px) and (min-width:760px) {


}

@media screen and (max-width:759px) {

  .section {

    flex-direction: column;
    justify-content: center;

  }

  .header-wrapper {

    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 100%;

  }

  .header-wrapper .property-img {

    margin: 0;

  }

  .header-wrapper .property-data {

    width: 100%;

  }

  .header-wrapper .property-data .top-wrapper {

    flex-direction: column;

  }

  .address-wrapper {

    width: 100%;

  }

  .bottom-section {

    flex-direction: column;

  }

  .services-wrapper {

    flex-wrap: nowrap;
    flex-direction: column;

  }

  .services-wrapper .service {

    width: 100%;

  }

  .services-wrapper .service div {

    justify-content: center;

  }

  #landlordAddEditView #innerWrapper {

    height: calc( 100% - 110px );

  }

  .date-wrapper {

    flex-direction: column;

  }

  .expose-header-section {

    flex-direction: column;

  }

  .expose-header-section .input-wrapper {

    width: 100%;

  }

  .expose-header-section .input-wrapper .bottom-wrapper {

    flex-direction: column;

  }

}