.menu-btn {

    border: 1px solid var(--lbl-color-cyan);
    border-radius: 5px;
    padding: 5px 10px 5px 5px;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

}

.menu-btn:hover {
    background-color: var(--lbl-color-cyan-hover);
    color: white;
}

.menu-btn:hover .icon {
    color: white;
}