.message-backview {

    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    height: 98%;
    margin-top: 100%;

    transition: all 0.2s ease-in-out;

}

.message-backview.opened {
    margin-top: 1%;
}

.mail-preview-card {

    background-color: var(--bg-color-white);
    width: 95%;
    margin-left: 5%;
    border-bottom: 1px solid var(--line-color);
    cursor: pointer;
    padding: 5px 0 ;

    transition: all 0.2s ease-in-out;

}

.mail-preview-card:hover {

    background-color: var(--tile-bg-selected);
}

.mail-card {

    flex-direction: column;
    width: 70%;

}

.mail-card.answer {

    margin-left: 30%;

}

.text-seperator {

    width: 50%;
    margin-left: 25%;
    padding: 10px;
    color: var(--lbl-color-lightblack);
    text-align: center;
    border-top: 1px solid var(--line-color);
  
}

.anlage-btn {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: auto;
    height: 30px;
    background-color: var(--lbl-color-cyan);
    border-radius: 5px;
    color: var(--lbl-color-black);
    cursor: pointer;
  
    transition: all 0.2s ease-in-out;
  
}

.anlage-btn.orange {

    background-color: darkorange;

}

.anlage-btn.orange:hover {

    background-color: rgb(213, 118, 1);

}

.anlage-btn:hover {

    background-color: var(--lbl-color-cyan-hover);

}

.mail-address-style {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 0px 2px 10px;
    background-color: rgb(210, 210, 210);
    border-radius: 3px;
    color: var(--lbl-color-black);
    cursor: pointer;

    transition: background-color 0.2s ease-in-out;

}

.mail-address-style:hover {

    background-color: rgb(195, 195, 195);
    
}

.mail-address-style.attachment {

    background-color: var(--lbl-color-cyan);
    padding-right: 10px;

}

.mail-address-style.attachment:hover {

    background-color: var(--lbl-color-cyan-hover);
    
}

@media screen and (min-width:1200px) {


}

@media screen and (max-width:1199px) and (min-width:950px) {


}

@media screen and (max-width:949px) and (min-width:760px) {


}

@media screen and (max-width:759px) {

    .mail-card {

        width: 90%;
    
    }
    
    .mail-card.answer {
    
        margin-left: 10%;
    
    }

    #mailCreationView #innerWrapper{

        top: 80px !important;
        height: calc( 100% - 90px ) !important;
    
    }

}