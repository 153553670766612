.navigation-cell {

    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px;
    background-color: var(--cell-bg-color);
    cursor: pointer;
    border-bottom: 1px solid var(--line-color);
    text-transform: uppercase;
    color: var(--lbl-color-lightblack);
  
    transition: all 0.2s ease;
  
  }
  
  .navigation-cell::before {
  
    content: attr(icon-name);
    padding-right: 5px;
    color: var(--lbl-color-cyan);
    text-transform: lowercase;
    font-family: 'Material Symbols Outlined';
  
  }
  
  .navigation-cell::after {
  
    content: 'navigate_next';
    position: absolute;
    right: 10px;
    color: var(--lbl-color-cyan);
    text-transform: lowercase;
    font-family: 'Material Symbols Outlined';
  
  }
  
  .navigation-cell:hover {
    background-color: var(--cell-bg-color-hover);
  }