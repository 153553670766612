.spanbar-wrapper {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.property-wrapper {

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.timespan-wrapper {

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    
}

@media screen and (max-width:1199px) and (min-width:950px) {



}

@media screen and (max-width:949px) and (min-width:760px) {




}

@media screen and (max-width:759px) {

    .spanbar-wrapper {

        flex-direction: column;

    }

    .property-wrapper {

        width: 100%;
    
    }
    
    .timespan-wrapper {
    
        width: 100%;
        
    }

}