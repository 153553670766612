.statusbar-btn {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

}

.statusbar-btn .icon {

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.0em;
    height: 100%;
    padding: 5px;

}

.statusbar-btn .label {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    color: var(--lbl-color-cyan);
    font-size: 0em;
    height: 0;
    overflow: clip;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
}

.statusbar-btn:hover .label {

    font-size: 0.8em;
    height: 20px;

}

.statusbar-btn:hover .icon {

    height: calc(100% - 20px);

}

.statusbar-btn:hover {
    background-color: var(--date-weekend-bg);
}

.statusbar-btn.selected {
    background-color: var(--date-weekend-bg);
}