.stay-backview {

    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    height: 98%;
    margin-top: 100%;

    transition: all 0.2s ease-in-out;

}

.stay-backview.opened {
    margin-top: 1%;
}

.stayaddedit-wrapper {

    display: flex;
    width: 100%;
    margin-top: 20px;
    gap: 30px;
    overflow: visible;

}

.stayaddedit-wrapper .section {

    display: flex;
    width: 50%;
    flex-direction: column;
    overflow: visible;

}

.stayaddedit-wrapper .section .input-wrapper {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    overflow: visible;

}

#stayGroupAddEditView .input-wrapper {

  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  padding: 3px 0;

}

.pdf-iframe {

  margin-top: 20px;
  width: 100%;
  height: 800px;

}

#editPdfView #innerWrapper {

  width: 600px;
  height: 900px;

}

.tune-settings-wrapper {

 display: flex;
 width: 100%;
 margin-top: 20px;
 padding: 5px 2px;
 gap: 10px;
 overflow: visible;

}

#stayConversationWrapperView #innerWrapper {

  width: 900px;

}

@media screen and (min-width:1200px) {


}

@media screen and (max-width:1199px) and (min-width:950px) {


}

@media screen and (max-width:949px) and (min-width:760px) {


}

@media screen and (max-width:759px) {

  .stayaddedit-wrapper {

    align-items: center;
    justify-content: center;
    flex-direction: column;

  }

  .stayaddedit-wrapper .section {

    width: 100%;
    padding: 0 5px;

  }

  .stayaddedit-wrapper .section .input-wrapper {

    flex-direction: column;

  }

  #stayGroupAddEditView .input-wrapper {

    flex-direction: column;
  
  }

  #stayGroupAddEditView .input-wrapper .input-template-style {

    width: 100% !important;

  }

  .pdf-iframe {

    height: 500px;
  
  }

  #editPdfView #innerWrapper {

    width: 100%;
    height: calc( 100% - 110px);
  
  }

  #stayConversationWrapperView #innerWrapper {

    width: 900px;
    height: calc( 100% - 110px);
  
  }

  .tune-settings-wrapper {

    flex-direction: column;
    align-items: center;
   
   }

}