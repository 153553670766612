.rate-bar {

    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .rate-bar .star {
  
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Material Symbols Outlined';
    font-size: 1.0em;
    color: rgb(205, 205, 0);
    cursor: pointer;
  
    transition: all 0.2s ease-in;
  
  }

  .rate-bar .star.filled {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }
  
  .rate-bar .star.blacklist {
    color: red;
  }
  
  .rate-bar .star:hover {
    transform: scale(1.4);
  }