nav {
    
    display: flex;
    position: fixed;
    background-color: var(--bg-color-navbar);
    width: 100%;
    height: 70px;
    border-bottom: 1px solid rgba(71, 71, 71, 0.5);
    box-shadow: 0px 0px 7px var(--navbar-shadow-color);
    z-index: 100;
    
    transition: all 1.8s ease;
    
}

nav ul {

    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

}

nav ul a {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100px;
    cursor: pointer;
    color: var(--lbl-color-cyan);
    padding-top: 10px;

    transition: all 0.2s ease-in-out;

}

nav ul a.active {
    color: var(--lbl-color-cyan-hover);
    padding-top: 0;
}

nav ul a .clickable-icon.icon {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.7rem;
    color: inherit;

}

nav ul a .title {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.8rem;
    padding: 2px;

}

.appIcon {
    
    height: 50px;
    width: 50px;
    border-radius:50%;
    margin-left: 30px;
    
}

.nav-menu {

    display: flex;
    position: absolute;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    font-size: 1.0rem;
    height: auto;
    top: 70px;
    background-color: var(--bg-color-navbar);
    padding: 10px;
    z-index: 1000 !important;

}

@media screen and (min-width:1200px) {

    

}

@media screen and (max-width:1199px) and (min-width:950px) {



}

@media screen and (max-width:949px) and (min-width:760px) {



}

@media screen and (max-width:759px) {

    

}
