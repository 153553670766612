#weekBar div {

    text-align: center;
    width: 100%;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

}

#weekBar div:hover {

    color: var(--lbl-color-cyan);

}

#weekBar div.selected {

    color: var(--lbl-color-cyan-hover);
    font-weight: bold;

}