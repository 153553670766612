.guest-backview {

    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    height: 98%;
    margin-top: 100%;

    transition: all 0.2s ease-in-out;

}

.guest-backview.opened {
    margin-top: 1%;
}

.input-wrapper {

    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    padding: 3px 0;

}

.contact-wrapper {

    display: flex;
    margin-top: 40px;
    align-items: center;
    width: 100%;
    gap: 20px;
    padding: 3px 0;
    overflow: visible;

}

@media screen and (min-width:1200px) {


}

@media screen and (max-width:1199px) and (min-width:950px) {


}

@media screen and (max-width:949px) and (min-width:760px) {


}

@media screen and (max-width:759px) {

  .input-wrapper {

    flex-direction: column;
    gap: 10px;

  }

  .contact-wrapper {

    flex-direction: column;
    gap: 10px;

  }

  .input-wrapper .input-template-style, .contact-wrapper .input-template-style {

    width: 100% !important;

  }

}