.login-box {

    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 100px 0 0 0;
    width: 500px;

}

#login-content {

    display: block;
    padding-top: 20px;
    width: 100%;

}

.input-style {
  
    display: block;
    margin-left: 10%;
    width: 80%;
    height: 30px;
    font-size: 1.0em;
    color: var(--lbl-new-input-color-lightblack);
    border: 1px solid black;
    border: 1px solid var(--boxshadow-color);
    background-color: rgba(0, 0, 0, 0.02);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-family: 'Economica', "Century Gothic", CenturyGothic, sans-serif;

    -moz-transition: 0.2s linear;
 -webkit-transition: 0.2s linear;
         transition: 0.2s linear;
  
}

.input-style::placeholder {

    color: rgb(139, 138, 138)

}

@media screen and (min-width:1200px) {
    
    
}

@media screen and (max-width:1199px) and (min-width:950px) {
    
    
}

@media screen and (max-width:949px) and (min-width:760px) {
    
    
}

@media screen and (max-width:759px) {
    
    .login-box {

        width: 96%;
    
    }
    
}
